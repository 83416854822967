<template>
  <div class="container">
    <div class="supply-header clearfix">
      <div class="supply-header-l fl">
        <div class="img-wrap"><img :src="info.logo" alt="" class="logo-avatar" /></div>
        
        <!-- <span class="logo-name line-clamp1">{{info.name}}</span> -->
      </div>
      <div class="supply-header-r fr clearfix">
        <el-dropdown
          class="avatar-container right-menu-item"
          trigger="click"
        >
          <div class="avatar-wrapper">
            <!-- <img
              v-if="avatar"
              :src="avatar|DFSImg(20,20)"
              class="user-avatar"
            > -->
            <div class="user-role">
              <span class="user-name">{{ $store.state.user.name }}</span>
              <div v-if="$store.state.user.currentRole" class="role-name">{{ $store.state.user.currentRole.roleName }}</div>
            </div>
            <i class="el-icon-caret-bottom" />
          </div>
          <el-dropdown-menu slot="dropdown" class="Navbar-dropdown">
            <el-dropdown-item v-for="(role,roleIndex) in $store.state.user.roleList" :key="roleIndex" class="role-dropdown">
              <span style="display:block;" @click="changeRole(role)">
                <i :class="{'opacity0':$store.state.user.currentRole.roleCode != role.roleCode}" class="el-icon-check"/>
                {{ role.roleName }}
              </span>
            </el-dropdown-item>
            <router-link to="/Home">
              <el-dropdown-item divided>首页</el-dropdown-item>
            </router-link>
            <!-- <el-dropdown-item>
              <span
                style="display:block;"
                @click="inviteGroup"
              >邀请企业</span>
            </el-dropdown-item> -->
            <el-dropdown-item>
              <span
                style="display:block;"
                @click="$router.push('/personal/info')"
              >个人资料</span>
            </el-dropdown-item>
            <!-- <el-dropdown-item >
              <span style="display:block;" @click="updatePassword">修改密码</span>
            </el-dropdown-item>-->
            <el-dropdown-item divided>
              <span
                style="display:block;"
                @click="logout"
              >退出</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <!-- <div class="r-title fl">供货渠道：</div> -->
        <!-- -->
      </div>
      <!-- <div class="report-system">
        <el-button
          size="mini"
          round
          style="float:right"
          @click="openReportProject"
          icon="el-icon-data-line">
          报表平台
        </el-button>
      </div> -->
    </div>
  </div>
</template>

<script>
import { queryListSupplierManager } from "@/api/mySupply";
import { getSupplierDetailByOrgId } from "@/api/waitImportProduct";
import info from "@/assets/common.js"
import {  getToken } from "@/utils/auth";
export default {
  components: {
    // headers,
    // distributors,
    // setting,
    // productManage,
    // importProduct
  },
  data() {
    return {
      productStatus: false,
      optionList: [],
      boundOrgId: "",
      groupId: "",
      walletServiceRate: "",
      info,
    };
  },
  methods: {
    changeId(val) {
      console.log(val, 8888);
      this.$store.commit("app/setBoundOrgId", val);
      localStorage.setItem("boundOrgId", val);
    },
    otherApplyIn() {
      window.open(process.env.VUE_APP_GHSC_HOST + "#/applyIn", "_blank");
    },
    queryList() {
      queryListSupplierManager({
        pageNum: 1,
        pageSize: 999,
        type: 2,
      }).then((res) => {
        if (res.data.code == "200") {
          this.optionList = res.data.data;
          let flag = false;
          if (localStorage.getItem("boundOrgId")) {
            this.optionList.forEach((ele) => {
              if (ele.boundOrgId == localStorage.getItem("boundOrgId")) {
                flag = true;
              }
            });
          }
          if (flag) {
            this.$store.commit(
              "app/setBoundOrgId",
              localStorage.getItem("boundOrgId")
            );
            this.boundOrgId = localStorage.getItem("boundOrgId");
          } else {
            this.boundOrgId = this.optionList[0].boundOrgId;
            this.$store.commit("app/setBoundOrgId", this.boundOrgId);
            localStorage.setItem("boundOrgId", this.boundOrgId);
          }

          getSupplierDetailByOrgId({ boundOrgId: this.boundOrgId }).then(
            (res) => {
              if (res.data.code === "200") {
                this.groupId = res.data.data.groupId;
                this.walletServiceRate = res.data.data.walletServiceRate
                  ? parseInt(res.data.data.walletServiceRate).toFixed(2)
                  : "0.00";
              }
            }
          );
        }
        console.log(res);
      });
    },
    logout() {
      this.$store.dispatch('FedLogOut').then(() => {
        this.$router.push('/login')
      })
    },
    changeRole(role) {
      if (this.$store.state.user.currentRole.roleCode !== role.roleCode) {
        this.$store.dispatch('ChangRole', role.roleCode).then(() => {
          window.open(window.origin, '_self')
        })
      }
    },
    openReportProject(){
      const token = getToken()
      if (!token) {
        this.$message.error('请重新登录')
        return
      }


      window.open(
        `${process.env.VUE_APP_REPORT_HOST}#/?token=${getToken()}`, '_blank'
      )
    }
  },
  mounted() {
    // this.queryList();
  },
};
</script>

<style lang="scss" scoped>
.container {
  //   width: $mainW;
  margin: 0 auto;
  height: 60px;
  line-height: 60px;
  .supply-header {
    width: 100%;
    background-color: white;
    height: 100%;
    border-bottom: 1px solid #d8d8d8;
    .supply-header-l {
      width: 280px;
      height: 100%;
      text-align: center;
      font-size: 22px;
      display: flex;
      .img-wrap{
        height: 100%;
        padding: 10px;
        box-sizing: border-box;
        img{
          height: 100%;
          vertical-align:inherit;
        }
      }
      .big-title {
        font-weight: bold;
      }
      
    }
    .report-system{
          display: flex;
          align-items: center;
          height: 100%;
          float: right;
          margin-right: 18px;
      }
    .supply-header-r {
      padding-right: 20px;

      .r-title {
        font-size: 16px;
        font-weight: bold;
      }
      .other {
        margin-left: 10px;
        cursor: pointer;
        // color: $mainColor;
      }
      .el-select {
        // margin-top: 28px;
      }
      .avatar-container {
        height: 50px;
        margin-right: 30px;
        margin-top: 10px;
        .avatar-wrapper {
          cursor: pointer;
          // margin-top: 5px;
          position: relative;
          display: flex;
          align-items: center;
          .user-avatar {
            width: 40px;
            height: 40px;
            border-radius: 20px;
          }
          .user-role{
            display: flex;
            align-items: center;
            .user-name {
              height: 40px;
              line-height: 40px;
              margin-left: 5px;
              font-size: 15pt;
            }
            .role-name{
              margin-left: 10px;
              background: #e5e5e5;
              height: 24px;
              line-height: 24px;
              padding: 0 10px;
              border-radius: 24px;
            }
          }
          .el-icon-caret-bottom {
            position: absolute;
            right: -20px;
            // top: 25px;
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>
