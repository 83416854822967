<template>
  <el-aside
    width="200px"
    :style="{ background: '#fff', height: 'calc(100vh - 56px)', overflow: 'auto' }"
    class="aside-container"
  >
    <el-menu
      class="el-menu-vertical-demo"
      router
      :default-active="$route.path"
    >
      <el-menu-item index="/Home">
        <span slot="title">
          <i class="el-icon-house"></i>
          <span>首页</span>
        </span>
      </el-menu-item>
      <el-submenu index="3" v-if="false">
        <template slot="title">
          <i class="el-icon-s-opportunity"></i>
          <span>道可智库</span>
        </template>
        <el-menu-item
          index=""
          @click="
            clickHanlder(
              'https://dk.taokor.cn/token?token=${token}'
            )
          "
        >
          企业CT评估系统
        </el-menu-item>
        <el-menu-item
          index=""
          @click="
            clickHanlder(
              'https://dk360c.taokor.cn/open/token?token=${token}'
            )
          "
        >
          企业360°评估系统
        </el-menu-item>
         <el-menu-item
          index=""
          @click="
            clickHanlder(
              'https://dk360c.taokor.cn/open/console/WVdOamIzVnVkRjloWkcxcGJqcGhaRzFwYmpFeU13PT0'
            )
          "
        >
          企业360°平台管理
        </el-menu-item>
        <el-menu-item
          index=""
          @click="
            clickHanlder('https://manager.taokor.cn/report/#/?token=${token}')
          "
        >
          自定义报告
        </el-menu-item>
      </el-submenu>
      <el-menu
        :show-timeout="200"
        :default-active="$route.path"
        :collapse="false"
        mode="vertical"
        router
        class="main-menu"
      >
        <sidebar-item
          v-for="(route, index) in permission_routers"
          :key="index"
          :item="route"
          :base-path="route.path"
        />
      </el-menu>
    </el-menu>
  </el-aside>
</template>

<script>
import { mapGetters } from "vuex";
import SidebarItem from "./SidebarItem";
import { getCurrentRoleCode } from "@/utils/auth";
import { getByRoleDaoKeMessage } from "@/api/login";
export default {
  components:{
    SidebarItem
  },
  computed: {
    ...mapGetters(["permission_routers"]),
  },
  mounted() {
    // console.log(this.permission_routers,'----permission_routers')
  },
  methods: {
    clickHanlder(path) {
      getByRoleDaoKeMessage(getCurrentRoleCode()).then(res => {
        if(res.data.code == 200){
          if (path.indexOf("${token}") != -1) {
            path = path.replace(/\$\{token\}/g, res.data.data);
          }
          console.log(path)
          window.open(path);
        }
      })


    },
  },
};
</script>


<style lang="scss" scoped>
</style>