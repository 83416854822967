<template>
  <div id="components-layout-demo-top-side-2">
    <el-container>
      <el-header :style="{ padding: 0, 'z-index': 10 }">
        <Headers />
      </el-header>
      <el-container style="background: #f0f2f5">
        <Aside />
        <AppMain />
      </el-container>
    </el-container>
  </div>
</template>
<script>
import Headers from "@/components/layout/Header";
import AppMain from "@/components/layout/AppMain";
import Aside from "@/components/layout/Aside";
export default {
  components: {
    Headers,
    AppMain,
    Aside
  },
};
</script>

<style lang="scss" scoped>
</style>
