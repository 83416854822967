var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-main',{staticStyle:{"height":"calc(100vh - 60px)","overflow":"hidden","padding":"10px","position":"relative"}},[_c('div',{style:({
      background: '#fff',
      padding: '15px',
      margin: 0,
      height: '95%',
      boxSizing: 'border-box',
      overflow: 'auto',
      'box-shadow': '1px 1px 1px 1px #ccc',
    })},[_c('router-view',{staticClass:"router-view"}),_c('div',{attrs:{"id":"children"}})],1),_c('div',{staticClass:"copyRight"},[_c('p',[_vm._v(_vm._s(_vm.info.copyright))])])])
}
var staticRenderFns = []

export { render, staticRenderFns }