<template>
  <el-main style="height: calc(100vh - 60px); overflow: hidden; padding: 10px;position:relative">
    <div
      :style="{
        background: '#fff',
        padding: '15px',
        margin: 0,
        height: '95%',
        boxSizing: 'border-box',
        overflow: 'auto',
        'box-shadow': '1px 1px 1px 1px #ccc',
      }"
    >
      <!-- 目录 -->
      <!-- <div
        style="
          padding-bottom: 10px;
          border-bottom: 1px solid #eee;
          margin-bottom: 10px;
        "
      > -->
      <!-- <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item
            v-for="(item, index) in breadcrumb"
            :to="item.path"
            :key="index"
            >{{ item.title }}</el-breadcrumb-item
          >
        </el-breadcrumb> -->
      <!-- </div> -->
      <router-view class="router-view"></router-view>
      <div id="children"></div>

      <!-- <i class="el-icon-loading"></i> -->
    </div>
    <!-- 版权 -->
    <div class="copyRight"><p>{{info.copyright}}</p></div>
  </el-main>
</template>
<script>
import info from "@/assets/common.js"
export default {
  data() {
    return {
      breadcrumb: [],
      info
    };
  },
  created() {
    //   面包屑
    // this.breadcrumb = this.list[this.$route.path];
  },
  watch: {
    // $route: function (newVal) {
    //   console.log(newVal);
    //   this.breadcrumb = this.list[newVal.path];
    // },
  },
};
</script>

<style lang="scss" scoped>
#components-layout-demo-top-side-2 {
  height: 100%;
  .header {
    padding: 0;
    z-index: 10;
  }
  .el-menu-vertical-demo {
    height: 100%;
  }
}
#components-layout-demo-top-side-2 .logo {
  width: 120px;
  height: 31px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px 28px 16px 0;
  float: left;
}
#children {
  z-index: 10;
}

.el-icon-loading {
  font-size: 60px;
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% + 100px);
  z-index: 0;
}
.copyRight{
  width: 100%;
  position: absolute;
  bottom: 10px;
  text-align: center;
  font-size: 14px;
}
</style>
