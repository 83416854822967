var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-aside',{staticClass:"aside-container",style:({ background: '#fff', height: 'calc(100vh - 56px)', overflow: 'auto' }),attrs:{"width":"200px"}},[_c('el-menu',{staticClass:"el-menu-vertical-demo",attrs:{"router":"","default-active":_vm.$route.path}},[_c('el-menu-item',{attrs:{"index":"/Home"}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_c('i',{staticClass:"el-icon-house"}),_c('span',[_vm._v("首页")])])]),(false)?_c('el-submenu',{attrs:{"index":"3"}},[_c('template',{slot:"title"},[_c('i',{staticClass:"el-icon-s-opportunity"}),_c('span',[_vm._v("道可智库")])]),_c('el-menu-item',{attrs:{"index":""},on:{"click":function($event){return _vm.clickHanlder(
            'https://dk.taokor.cn/token?token=${token}'
          )}}},[_vm._v(" 企业CT评估系统 ")]),_c('el-menu-item',{attrs:{"index":""},on:{"click":function($event){return _vm.clickHanlder(
            'https://dk360c.taokor.cn/open/token?token=${token}'
          )}}},[_vm._v(" 企业360°评估系统 ")]),_c('el-menu-item',{attrs:{"index":""},on:{"click":function($event){return _vm.clickHanlder(
            'https://dk360c.taokor.cn/open/console/WVdOamIzVnVkRjloWkcxcGJqcGhaRzFwYmpFeU13PT0'
          )}}},[_vm._v(" 企业360°平台管理 ")]),_c('el-menu-item',{attrs:{"index":""},on:{"click":function($event){return _vm.clickHanlder('https://manager.taokor.cn/report/#/?token=${token}')}}},[_vm._v(" 自定义报告 ")])],2):_vm._e(),_c('el-menu',{staticClass:"main-menu",attrs:{"show-timeout":200,"default-active":_vm.$route.path,"collapse":false,"mode":"vertical","router":""}},_vm._l((_vm.permission_routers),function(route,index){return _c('sidebar-item',{key:index,attrs:{"item":route,"base-path":route.path}})}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }