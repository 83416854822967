/*
 * @Author: your name
 * @Date: 2020-08-13 10:09:28
 * @LastEditTime: 2020-08-13 10:10:38
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \ghsc-front-pc\src\api\mySupply\index.js
 */
import request from '@/utils/request.js'

// 查询我的供货商列表
export function queryMySupplyList (data) {
  return request({
    url: `${process.env.VUE_APP_OLSHOP_URL}/procureService/supplierManagerInfo/query_my_supplier_list_by_page`,
    method: 'post',
    data
  })
}
 //POST 查询供应商列表POST 
 export function queryListSupplierManager (data) {
  return request({
    url: `${process.env.VUE_APP_OLSHOP_URL}/procureService/supplierManagerInfo/query_supplier_org_list_by_page`,
    method: 'post',
    data: data
  })
}