<template>
  <div v-if="!item.hidden&&item.children" class="menu-wrapper" v-loading="fullscreenLoading" element-loading-text="跳转中">
    <router-link v-if="!item.meta && hasOneShowingChild(item.children) && !onlyOneChild.children&&! item.alwaysShow" :to="resolvePath(onlyOneChild.path)" class="el-menu">
      <el-menu-item :index="resolvePath(onlyOneChild.path)" @click="clickHanlder(onlyOneChild.path)">
        <svg-icon v-if="onlyOneChild.meta&&onlyOneChild.meta.icon" :icon-class="onlyOneChild.meta.icon"/>
        <span v-if="onlyOneChild.meta&&onlyOneChild.meta.title" slot="title">{{ generateTitle(onlyOneChild.meta.title) }}</span>
      </el-menu-item>
    </router-link>

    <el-submenu v-else :index="(item.meta || {}) .id || item.name || item.path">
      <template slot="title">
        <svg-icon v-if="item.meta&&item.meta.icon" :icon-class="item.meta.icon"/>
        <span v-if="item.meta&&item.meta.title" slot="title">{{ generateTitle(item.meta.title) }}</span>
      </template>

      <template v-for="child in item.children" v-if="!child.hidden">
        <sidebar-item v-if="child.children&&child.children.length>0" :is-nest="true" :item="child" :key="child.id" :base-path="resolvePath(child.path)" class="nest-menu"/>

        <router-link v-else :to="resolvePath2(child)" :key="child.id">
          <el-menu-item :index="resolvePath(child.path) || child.meta.id" @click="clickHanlder(child.path)">
            <svg-icon v-if="child.meta&&child.meta.icon" :icon-class="child.meta.icon"/>
            <span v-if="child.meta&&child.meta.title" slot="title"><span id="circleTo" v-loading.fullscreen.lock="fullscreenLoading"><span class="circleTo"/></span>{{ generateTitle(child.meta.title) }}</span>
          </el-menu-item>
        </router-link>
      </template>
    </el-submenu>

  </div>
</template>

<script>
import path from 'path'
import { generateTitle } from '@/utils/i18n'
import { getToken } from '@/utils/auth'
import { getThirdToken } from '@/api/ssoSiteConfig/ssoSiteConfig'
import { getCurrentRoleCode } from "@/utils/auth";
import { getByRoleDaoKeMessage } from "@/api/login";
export default {
  name: 'SidebarItem',
  props: {
    // route object
    item: {
      type: Object,
      required: true
    },
    isNest: {
      type: Boolean,
      default: false
    },
    basePath: {
      type: String,
      default: ''
    }
  },
  data() {
    // console.log(JSON.stringify(this.item))
    return {
      onlyOneChild: null,
      fullscreenLoading: false
    }
  },
  methods: {
    hasOneShowingChild(children) {
      const showingChildren = children.filter(item => {
        if (item.hidden) {
          return false
        } else {
          // temp set(will be used if only has one showing child )
          this.onlyOneChild = item
          return true
        }
      })
      if (showingChildren.length === 1) {
        return true
      }
      return false
    },
    resolvePath(...paths) {
      // console.log(paths)
      if (paths[0] == 'goods/supplyMarketplace') {
        // window.open( `http://test-gh.mayi888.cn/#/?token=${getToken()}`)
        return ''
        // return 'goods/supplyMarketplace1'
      } else if (paths[0] && (paths[0].indexOf('http://') == 0 || paths[0].indexOf('https://') == 0 || paths[0].indexOf('/http://') == 0 || paths[0].indexOf('/https://') == 0)) {
        return ''
      }

      return path.resolve(this.basePath, ...paths)
    },
    resolvePath2(route) {
      // console.log(route.meta.query)
      const loca = {
        'path': this.resolvePath(route.path)
      }
      if (route.meta.query) {
        loca.query = route.meta.query
      }
      // return
      return loca
    },
    clickHanlder(path) {
      console.log(path,'----path')
      if (path == 'goods/supplyMarketplace') {
        window.open(`${process.env.SUPPLY_URL}#/?token=${getToken()}`)
      } else if (path && (path.indexOf('http://') == 0 || path.indexOf('https://') == 0 || path.indexOf('/http://') == 0 || path.indexOf('/https://') == 0)) {
        if (path.indexOf('/') == 0) {
          path.substring(1)
        }
        if (path.indexOf('${token}') != -1) {
          path = path.replace(/\$\{token\}/g, getToken())
        }
        if(path.indexOf('${href}') != -1) {
          path = path.replace(/\$\{href\}/g, location.origin)
        }
        if(path.indexOf('${ssoToken}') != -1) {
          let reqPath = encodeURIComponent(path)
          console.log("path", reqPath)
          this.fullscreenLoading = true;
          setTimeout(() => {
            this.fullscreenLoading = false;
          }, 2000);
          getByRoleDaoKeMessage(getCurrentRoleCode()).then(res => {
            if(res.data.code == 200){
              if (path.indexOf("${ssoToken}") != -1) {
                path = path.replace(/\$\{ssoToken\}/g, res.data.data);
              }
              console.log(path)
              window.open(path);
            }
          })
        } else {
          console.log('进入window.open(path)')
          window.open(path)
        }
      }
    },
    generateTitle
  }
}
</script>

