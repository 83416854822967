/*
 * @Author: your name
 * @Date: 2020-08-12 11:49:52
 * @LastEditTime: 2020-09-08 14:23:12
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ghsc-front-pc\src\api\waitImportProduct\index.js
 */
import request from '@/utils/request.js'

// 查询待导入商品
export function queryWaitImportProductList (data) {
  return request({
    url: `${process.env.VUE_APP_OLSHOP_URL}/goodsService/terminal/query_wait_import_supply_market_product`,
    method: 'post',
    data: data
  })
}

// 移除待导入商品
export function removeWaitImportProduct (data) {
  return request({
    url: `${process.env.VUE_APP_OLSHOP_URL}/goodsService/terminal/supply_market_product_remove_wait_import`,
    method: 'post',
    data: data
  })
}

// 导入商品
export function importProduct (data) {
  return request({
    url: `${process.env.VUE_APP_OLSHOP_URL}/goodsService/terminal/batch_add_proxy_sale_product`,
    method: 'post',
    data: data
  })
}

// POST 根据组织ID查询供应商详情
export function getSupplierDetailByOrgId (data) {
  return request({
    url: `${process.env.VUE_APP_OLSHOP_URL}/procureService/supplierManagerInfo/getSupplierDetailByOrgId`,
    method: 'post',
    data: data
  })
}
// POST 修改供应商经营信息
export function updateSupplierBusiness (data) {
  return request({
    url: `${process.env.VUE_APP_OLSHOP_URL}/procureService/supplierManagerInfo/updateSupplierBusiness`,
    method: 'post',
    data: data
  })
}
// POST /supplierManagerInfo/updateSupplierContactWay 修改供应商联系方式信息
export function updateSupplierContactWay (data) {
  return request({
    url: `${process.env.VUE_APP_OLSHOP_URL}/procureService/supplierManagerInfo/updateSupplierContactWay`,
    method: 'post',
    data: data
  })
}
// POST /supplierManagerInfo/updateSupplierDistribution 修改供应商分销说明
export function updateSupplierDistribution (data) {
  return request({
    url: `${process.env.VUE_APP_OLSHOP_URL}/procureService/supplierManagerInfo/updateSupplierDistribution`,
    method: 'post',
    data: data
  })
}
// POST 分页查询我的分销商列表
export function query_my_distributor_list_by_page (data) {
  return request({
    url: `${process.env.VUE_APP_OLSHOP_URL}/procureService/supplierManagerInfo/query_my_distributor_list_by_page`,
    method: 'post',
    data: data
  })
}
// POST  分页查询某个分销商分销了某个供货商的商品列表
export function query_distribution_product_by_page (data) {
  return request({
    url: `${process.env.VUE_APP_OLSHOP_URL}/goodsService/terminal/query_distribution_product_by_page`,
    method: 'post',
    data: data
  })
}

export function query_export_wait_import_supply_market_goods_list (data) {
  return request({
    url: `${process.env.VUE_APP_OLSHOP_URL}/goodsService/terminal/query_export_wait_import_supply_market_goods_list`,
    method: 'post',
    data: data
  })
}
